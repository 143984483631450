<!-- eslint-disable vue/no-template-shadow -->
<template>
  <div>
    <v-simple-table v-if="!isLoading">
      <template v-slot:default>
        <tbody>
          <!--PRODUCTS-->
          <tr>
            <th>
              {{ $t('lbl.product') }}
            </th>
            <th :colspan="parseInt(typeAfiliados.length, 10) + 2"></th>
            <th class="text-right">
              <v-btn
                icon
                @click="showItemProduct()"
              >
                <v-icon>
                  {{ showRowProduct ? icons.mdiMenuDown : icons.mdiMenuRight }}
                </v-icon>
              </v-btn>
            </th>
          </tr>
          <fragment v-if="showRowProduct">
            <tr>
              <th class="text-uppercase">
                <b>{{ $t('lbl.identificador') }}</b>
              </th>
              <th>
                <b>{{ $t('lbl.fisic') }}</b>
              </th>
              <th>
                <b>Online</b>
              </th>

              <th
                v-for="(afiliado, j) in typeAfiliados"
                :key="j"
                class="text-center"
              >
                <b>{{ afiliado.name }}</b>
              </th>

              <th
                v-if="permisos.includes('markup:edit') || permisos.includes('markup:destroy') || permisos.includes('*')"
                width="150px"
                class="text-uppercase"
              >
                <b>{{ $t('lbl.actions') }}</b>
              </th>
            </tr>

            <tr
              v-for="(mark, indM) in itemsProduct"
              :key="indM"
            >
              <td>
                {{ mark.identificador }}
              </td>
              <td>
                <span v-if="mark.data_sale_fisic.value">
                  {{
                    `${mark.data_sale_fisic.is_value ? '$' : ''}
                    ${mark.data_sale_fisic.value}
                    ${!mark.data_sale_fisic.is_value ? '%' : ''}`
                  }}
                </span>
              </td>
              <td>
                <span v-if="mark.data_sale_online.value">
                  {{
                    `${mark.data_sale_online.is_value ? '$' : ''}
                    ${mark.data_sale_online.value}
                    ${!mark.data_sale_online.is_value ? '%' : ''}`
                  }}
                </span>
              </td>
              <td
                v-for="(afiliado, j) in typeAfiliados"
                :key="j"
                class="text-center"
              >
                <fragment
                  v-for="(dataAfil, indAd) in mark.data_sale_afiliate"
                  :key="indAd"
                >
                  <fragment v-if="dataAfil.type_id === afiliado.id">
                    <span v-if="dataAfil.value">
                      {{
                        `${dataAfil.is_value ? '$' : ''}
                        ${dataAfil.value}
                        ${!dataAfil.is_value ? '%' : ''}`
                      }}
                    </span>
                  </fragment>
                </fragment>
              </td>
              <td
                v-if="permisos.includes('markup:edit') || permisos.includes('markup:destroy') || permisos.includes('*')"
              >
                <v-tooltip
                  v-if="permisos.includes('markup:edit') || permisos.includes('*')"
                  top
                  color="primary"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      icon
                      v-bind="attrs"
                      v-on="on"
                      @click="editItem(mark)"
                    >
                      <v-icon>{{ icons.mdiSquareEditOutline }}</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t('btn.edit') }}</span>
                </v-tooltip>
                <v-tooltip
                  v-if="permisos.includes('markup:destroy') || permisos.includes('*')"
                  top
                  color="primary"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      icon
                      v-bind="attrs"
                      v-on="on"
                      @click="deleteItem(mark)"
                    >
                      <v-icon>{{ icons.mdiDeleteOutline }}</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t('btn.delete') }}</span>
                </v-tooltip>
              </td>
            </tr>
          </fragment>

          <tr>
            <th
              :colspan="parseInt(typeAfiliados.length, 10) + 4"
              style="height: 10px"
            ></th>
          </tr>
          <!--PROVEEDOR-->
          <tr>
            <th>
              {{ $t('lbl.proveedor') }}
            </th>
            <th :colspan="parseInt(typeAfiliados.length, 10) + 2"></th>
            <th class="text-right">
              <v-btn
                icon
                @click="showItemProveedor()"
              >
                <v-icon>
                  {{ showRowProveedor ? icons.mdiMenuDown : icons.mdiMenuRight }}
                </v-icon>
              </v-btn>
            </th>
          </tr>
          <fragment v-if="showRowProveedor">
            <tr>
              <th class="text-uppercase">
                <b>{{ $t('lbl.identificador') }}</b>
              </th>
              <th>
                <b>{{ $t('lbl.fisic') }}</b>
              </th>
              <th>
                <b>Online</b>
              </th>

              <th
                v-for="(afiliado, j) in typeAfiliados"
                :key="j"
                class="text-center"
              >
                <b>{{ afiliado.name }}</b>
              </th>

              <th
                v-if="permisos.includes('markup:edit') || permisos.includes('markup:destroy') || permisos.includes('*')"
                width="150px"
                class="text-uppercase"
              >
                <b>{{ $t('lbl.actions') }}</b>
              </th>
            </tr>

            <tr
              v-for="(mark, indM) in itemsProveedor"
              :key="indM"
            >
              <td>
                {{ mark.identificador }}
              </td>
              <td>
                <span v-if="mark.data_sale_fisic.value">
                  {{
                    `${mark.data_sale_fisic.is_value ? '$' : ''}
                    ${mark.data_sale_fisic.value}
                    ${!mark.data_sale_fisic.is_value ? '%' : ''}`
                  }}
                </span>
              </td>
              <td>
                <span v-if="mark.data_sale_online.value">
                  {{
                    `${mark.data_sale_online.is_value ? '$' : ''}
                    ${mark.data_sale_online.value}
                    ${!mark.data_sale_online.is_value ? '%' : ''}`
                  }}
                </span>
              </td>
              <td
                v-for="(afiliado, j) in typeAfiliados"
                :key="j"
                class="text-center"
              >
                <fragment
                  v-for="(dataAfil, indAd) in mark.data_sale_afiliate"
                  :key="indAd"
                >
                  <fragment v-if="dataAfil.type_id === afiliado.id">
                    <span v-if="dataAfil.value">
                      {{
                        `${dataAfil.is_value ? '$' : ''}
                        ${dataAfil.value}
                        ${!dataAfil.is_value ? '%' : ''}`
                      }}
                    </span>
                  </fragment>
                </fragment>
              </td>
              <td
                v-if="permisos.includes('markup:edit') || permisos.includes('markup:destroy') || permisos.includes('*')"
              >
                <v-tooltip
                  v-if="permisos.includes('markup:edit') || permisos.includes('*')"
                  top
                  color="primary"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      icon
                      v-bind="attrs"
                      v-on="on"
                      @click="editItem(mark)"
                    >
                      <v-icon>{{ icons.mdiSquareEditOutline }}</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t('btn.edit') }}</span>
                </v-tooltip>
                <v-tooltip
                  v-if="permisos.includes('markup:destroy') || permisos.includes('*')"
                  top
                  color="primary"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      icon
                      v-bind="attrs"
                      v-on="on"
                      @click="deleteItem(mark)"
                    >
                      <v-icon>{{ icons.mdiDeleteOutline }}</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t('btn.delete') }}</span>
                </v-tooltip>
              </td>
            </tr>
          </fragment>

          <tr>
            <th
              :colspan="parseInt(typeAfiliados.length, 10) + 4"
              style="height: 10px"
            ></th>
          </tr>
          <!--CONTRATE-->
          <tr>
            <th>
              {{ $t('lbl.contrato') }}
            </th>
            <th :colspan="parseInt(typeAfiliados.length, 10) + 2"></th>
            <th class="text-right">
              <v-btn
                icon
                @click="showItemContrate()"
              >
                <v-icon>
                  {{ showRowContrate ? icons.mdiMenuDown : icons.mdiMenuRight }}
                </v-icon>
              </v-btn>
            </th>
          </tr>
          <fragment v-if="showRowContrate">
            <tr>
              <th class="text-uppercase">
                <b>{{ $t('lbl.identificador') }}</b>
              </th>
              <th>
                <b>{{ $t('lbl.fisic') }}</b>
              </th>
              <th>
                <b>Online</b>
              </th>

              <th
                v-for="(afiliado, j) in typeAfiliados"
                :key="j"
                class="text-center"
              >
                <b>{{ afiliado.name }}</b>
              </th>

              <th
                v-if="permisos.includes('markup:edit') || permisos.includes('markup:destroy') || permisos.includes('*')"
                width="150px"
                class="text-uppercase"
              >
                <b>{{ $t('lbl.actions') }}</b>
              </th>
            </tr>

            <tr
              v-for="(mark, indM) in itemsContrate"
              :key="indM"
            >
              <td>
                {{ mark.identificador }}
              </td>
              <td>
                <span v-if="mark.data_sale_fisic.value">
                  {{
                    `${mark.data_sale_fisic.is_value ? '$' : ''}
                    ${mark.data_sale_fisic.value}
                    ${!mark.data_sale_fisic.is_value ? '%' : ''}`
                  }}
                </span>
              </td>
              <td>
                <span v-if="mark.data_sale_online.value">
                  {{
                    `${mark.data_sale_online.is_value ? '$' : ''}
                    ${mark.data_sale_online.value}
                    ${!mark.data_sale_online.is_value ? '%' : ''}`
                  }}
                </span>
              </td>
              <td
                v-for="(afiliado, j) in typeAfiliados"
                :key="j"
                class="text-center"
              >
                <fragment
                  v-for="(dataAfil, indAd) in mark.data_sale_afiliate"
                  :key="indAd"
                >
                  <fragment v-if="dataAfil.type_id === afiliado.id">
                    <span v-if="dataAfil.value">
                      {{
                        `${dataAfil.is_value ? '$' : ''}
                        ${dataAfil.value}
                        ${!dataAfil.is_value ? '%' : ''}`
                      }}
                    </span>
                  </fragment>
                </fragment>
              </td>
              <td
                v-if="permisos.includes('markup:edit') || permisos.includes('markup:destroy') || permisos.includes('*')"
              >
                <v-tooltip
                  v-if="permisos.includes('markup:edit') || permisos.includes('*')"
                  top
                  color="primary"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      icon
                      v-bind="attrs"
                      v-on="on"
                      @click="editItem(mark)"
                    >
                      <v-icon>{{ icons.mdiSquareEditOutline }}</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t('btn.edit') }}</span>
                </v-tooltip>
                <v-tooltip
                  v-if="permisos.includes('markup:destroy') || permisos.includes('*')"
                  top
                  color="primary"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      icon
                      v-bind="attrs"
                      v-on="on"
                      @click="deleteItem(mark)"
                    >
                      <v-icon>{{ icons.mdiDeleteOutline }}</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t('btn.delete') }}</span>
                </v-tooltip>
              </td>
            </tr>
          </fragment>

          <tr>
            <th
              :colspan="parseInt(typeAfiliados.length, 10) + 4"
              style="height: 10px"
            ></th>
          </tr>
          <!--HOTEL-->
          <tr>
            <th>
              {{ $t('lbl.hotel') }}
            </th>
            <th :colspan="parseInt(typeAfiliados.length, 10) + 2"></th>
            <th class="text-right">
              <v-btn
                icon
                @click="showItemHotel()"
              >
                <v-icon>
                  {{ showRowHotel ? icons.mdiMenuDown : icons.mdiMenuRight }}
                </v-icon>
              </v-btn>
            </th>
          </tr>
          <fragment v-if="showRowHotel">
            <tr>
              <th class="text-uppercase">
                <b>{{ $t('lbl.identificador') }}</b>
              </th>
              <th>
                <b>{{ $t('lbl.fisic') }}</b>
              </th>
              <th>
                <b>Online</b>
              </th>

              <th
                v-for="(afiliado, j) in typeAfiliados"
                :key="j"
                class="text-center"
              >
                <b>{{ afiliado.name }}</b>
              </th>

              <th
                v-if="permisos.includes('markup:edit') || permisos.includes('markup:destroy') || permisos.includes('*')"
                width="150px"
                class="text-uppercase"
              >
                <b>{{ $t('lbl.actions') }}</b>
              </th>
            </tr>

            <tr
              v-for="(mark, indM) in itemsHotel"
              :key="indM"
            >
              <td>
                {{ mark.identificador }}
              </td>
              <td>
                <span v-if="mark.data_sale_fisic.value">
                  {{
                    `${mark.data_sale_fisic.is_value ? '$' : ''}
                    ${mark.data_sale_fisic.value}
                    ${!mark.data_sale_fisic.is_value ? '%' : ''}`
                  }}
                </span>
              </td>
              <td>
                <span v-if="mark.data_sale_online.value">
                  {{
                    `${mark.data_sale_online.is_value ? '$' : ''}
                    ${mark.data_sale_online.value}
                    ${!mark.data_sale_online.is_value ? '%' : ''}`
                  }}
                </span>
              </td>
              <td
                v-for="(afiliado, j) in typeAfiliados"
                :key="j"
                class="text-center"
              >
                <fragment
                  v-for="(dataAfil, indAd) in mark.data_sale_afiliate"
                  :key="indAd"
                >
                  <fragment v-if="dataAfil.type_id === afiliado.id">
                    <span v-if="dataAfil.value">
                      {{
                        `${dataAfil.is_value ? '$' : ''}
                        ${dataAfil.value}
                        ${!dataAfil.is_value ? '%' : ''}`
                      }}
                    </span>
                  </fragment>
                </fragment>
              </td>
              <td
                v-if="permisos.includes('markup:edit') || permisos.includes('markup:destroy') || permisos.includes('*')"
              >
                <v-tooltip
                  v-if="permisos.includes('markup:edit') || permisos.includes('*')"
                  top
                  color="primary"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      icon
                      v-bind="attrs"
                      v-on="on"
                      @click="editItem(mark)"
                    >
                      <v-icon>{{ icons.mdiSquareEditOutline }}</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t('btn.edit') }}</span>
                </v-tooltip>
                <v-tooltip
                  v-if="permisos.includes('markup:destroy') || permisos.includes('*')"
                  top
                  color="primary"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      icon
                      v-bind="attrs"
                      v-on="on"
                      @click="deleteItem(mark)"
                    >
                      <v-icon>{{ icons.mdiDeleteOutline }}</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t('btn.delete') }}</span>
                </v-tooltip>
              </td>
            </tr>
          </fragment>

          <tr>
            <th
              :colspan="parseInt(typeAfiliados.length, 10) + 4"
              style="height: 10px"
            ></th>
          </tr>
          <!--ROOMS-->
          <tr>
            <th>
              {{ $t('lbl.room') }}
            </th>
            <th :colspan="parseInt(typeAfiliados.length, 10) + 2"></th>
            <th class="text-right">
              <v-btn
                icon
                @click="showItemRoom()"
              >
                <v-icon>
                  {{ showRowRoom ? icons.mdiMenuDown : icons.mdiMenuRight }}
                </v-icon>
              </v-btn>
            </th>
          </tr>
          <fragment v-if="showRowRoom">
            <tr>
              <th class="text-uppercase">
                <b>{{ $t('lbl.identificador') }}</b>
              </th>
              <th>
                <b>{{ $t('lbl.fisic') }}</b>
              </th>
              <th>
                <b>Online</b>
              </th>

              <th
                v-for="(afiliado, j) in typeAfiliados"
                :key="j"
                class="text-center"
              >
                <b>{{ afiliado.name }}</b>
              </th>

              <th
                v-if="permisos.includes('markup:edit') || permisos.includes('markup:destroy') || permisos.includes('*')"
                width="150px"
                class="text-uppercase"
              >
                <b>{{ $t('lbl.actions') }}</b>
              </th>
            </tr>

            <tr
              v-for="(mark, indM) in itemsRooms"
              :key="indM"
            >
              <td>
                {{ mark.identificador }}
              </td>
              <td>
                <span v-if="mark.data_sale_fisic.value">
                  {{
                    `${mark.data_sale_fisic.is_value ? '$' : ''}
                    ${mark.data_sale_fisic.value}
                    ${!mark.data_sale_fisic.is_value ? '%' : ''}`
                  }}
                </span>
              </td>
              <td>
                <span v-if="mark.data_sale_online.value">
                  {{
                    `${mark.data_sale_online.is_value ? '$' : ''}
                    ${mark.data_sale_online.value}
                    ${!mark.data_sale_online.is_value ? '%' : ''}`
                  }}
                </span>
              </td>
              <td
                v-for="(afiliado, j) in typeAfiliados"
                :key="j"
                class="text-center"
              >
                <fragment
                  v-for="(dataAfil, indAd) in mark.data_sale_afiliate"
                  :key="indAd"
                >
                  <fragment v-if="dataAfil.type_id === afiliado.id">
                    <span v-if="dataAfil.value">
                      {{
                        `${dataAfil.is_value ? '$' : ''}
                        ${dataAfil.value}
                        ${!dataAfil.is_value ? '%' : ''}`
                      }}
                    </span>
                  </fragment>
                </fragment>
              </td>
              <td
                v-if="permisos.includes('markup:edit') || permisos.includes('markup:destroy') || permisos.includes('*')"
              >
                <v-tooltip
                  v-if="permisos.includes('markup:edit') || permisos.includes('*')"
                  top
                  color="primary"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      icon
                      v-bind="attrs"
                      v-on="on"
                      @click="editItem(mark)"
                    >
                      <v-icon>{{ icons.mdiSquareEditOutline }}</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t('btn.edit') }}</span>
                </v-tooltip>
                <v-tooltip
                  v-if="permisos.includes('markup:destroy') || permisos.includes('*')"
                  top
                  color="primary"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      icon
                      v-bind="attrs"
                      v-on="on"
                      @click="deleteItem(mark)"
                    >
                      <v-icon>{{ icons.mdiDeleteOutline }}</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t('btn.delete') }}</span>
                </v-tooltip>
              </td>
            </tr>
          </fragment>

          <tr>
            <th
              :colspan="parseInt(typeAfiliados.length, 10) + 4"
              style="height: 10px"
            ></th>
          </tr>
          <!--TEMPORADAS-->
          <tr>
            <th>
              {{ $t('lbl.temporadas') }}
            </th>
            <th :colspan="parseInt(typeAfiliados.length, 10) + 2"></th>
            <th class="text-right">
              <v-btn
                icon
                @click="showItemTemporada()"
              >
                <v-icon>
                  {{ showRowTemporada ? icons.mdiMenuDown : icons.mdiMenuRight }}
                </v-icon>
              </v-btn>
            </th>
          </tr>
          <fragment v-if="showRowTemporada">
            <tr>
              <th class="text-uppercase">
                <b>{{ $t('lbl.identificador') }}</b>
              </th>
              <th>
                <b>{{ $t('lbl.fisic') }}</b>
              </th>
              <th>
                <b>Online</b>
              </th>

              <th
                v-for="(afiliado, j) in typeAfiliados"
                :key="j"
                class="text-center"
              >
                <b>{{ afiliado.name }}</b>
              </th>

              <th
                v-if="permisos.includes('markup:edit') || permisos.includes('markup:destroy') || permisos.includes('*')"
                width="150px"
                class="text-uppercase"
              >
                <b>{{ $t('lbl.actions') }}</b>
              </th>
            </tr>

            <tr
              v-for="(mark, indM) in itemsTemporada"
              :key="indM"
            >
              <td>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <span
                      v-bind="attrs"
                      v-on="on"
                    >{{ mark.identificador | truncate(30) }} {{ mark.nameSeason }}</span>
                  </template>
                  <span>{{ mark.identificador }}</span>
                </v-tooltip>
              </td>
              <td>
                <span v-if="mark.data_sale_fisic.value">
                  {{
                    `${mark.data_sale_fisic.is_value ? '$' : ''}
                    ${mark.data_sale_fisic.value}
                    ${!mark.data_sale_fisic.is_value ? '%' : ''}`
                  }}
                </span>
              </td>
              <td>
                <span v-if="mark.data_sale_online.value">
                  {{
                    `${mark.data_sale_online.is_value ? '$' : ''}
                    ${mark.data_sale_online.value}
                    ${!mark.data_sale_online.is_value ? '%' : ''}`
                  }}
                </span>
              </td>
              <td
                v-for="(afiliado, j) in typeAfiliados"
                :key="j"
                class="text-center"
              >
                <fragment
                  v-for="(dataAfil, indAd) in mark.data_sale_afiliate"
                  :key="indAd"
                >
                  <fragment v-if="dataAfil.type_id === afiliado.id">
                    <span v-if="dataAfil.value">
                      {{
                        `${dataAfil.is_value ? '$' : ''}
                        ${dataAfil.value}
                        ${!dataAfil.is_value ? '%' : ''}`
                      }}
                    </span>
                  </fragment>
                </fragment>
              </td>
              <td
                v-if="permisos.includes('markup:edit') || permisos.includes('markup:destroy') || permisos.includes('*')"
              >
                <v-tooltip
                  v-if="permisos.includes('markup:edit') || permisos.includes('*')"
                  top
                  color="primary"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      icon
                      v-bind="attrs"
                      v-on="on"
                      @click="editItem(mark)"
                    >
                      <v-icon>{{ icons.mdiSquareEditOutline }}</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t('btn.edit') }}</span>
                </v-tooltip>
                <v-tooltip
                  v-if="permisos.includes('markup:destroy') || permisos.includes('*')"
                  top
                  color="primary"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      icon
                      v-bind="attrs"
                      v-on="on"
                      @click="deleteItem(mark)"
                    >
                      <v-icon>{{ icons.mdiDeleteOutline }}</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t('btn.delete') }}</span>
                </v-tooltip>
              </td>
            </tr>
          </fragment>

          <tr>
            <th
              :colspan="parseInt(typeAfiliados.length, 10) + 4"
              style="height: 10px"
            ></th>
          </tr>
          <!--FECHAS
          <tr>
            <th>
              {{ $t('lbl.date') }}
            </th>
            <th :colspan="parseInt(typeAfiliados.length, 10) + 2"></th>
            <th class="text-right">
              <v-btn
                icon
                @click="showItemDate()"
              >
                <v-icon>
                  {{ showRowDate ? icons.mdiMenuDown : icons.mdiMenuRight }}
                </v-icon>
              </v-btn>
            </th>
          </tr>
          <fragment v-if="showRowDate">
            <tr>
              <th class="text-uppercase">
                <b>{{ $t('lbl.identificador') }}</b>
              </th>
              <th>
                <b>{{ $t('lbl.fisic') }}</b>
              </th>
              <th>
                <b>Online</b>
              </th>

              <th
                v-for="(afiliado, j) in typeAfiliados"
                :key="j"
                class="text-center"
              >
                <b>{{ afiliado.name }}</b>
              </th>

              <th
                v-if="permisos.includes('markup:edit') || permisos.includes('markup:destroy') || permisos.includes('*')"
                width="150px"
                class="text-uppercase"
              >
                <b>{{ $t('lbl.actions') }}</b>
              </th>
            </tr>

            <tr
              v-for="(mark, indM) in itemsDates"
              :key="indM"
            >
              <td>
                {{ mark.identificador }}
              </td>
              <td>
                <span v-if="mark.data_sale_fisic.value">
                  {{
                    `${mark.data_sale_fisic.is_value ? '$' : ''}
                    ${mark.data_sale_fisic.value}
                    ${!mark.data_sale_fisic.is_value ? '%' : ''}`
                  }}
                </span>
              </td>
              <td>
                <span v-if="mark.data_sale_online.value">
                  {{
                    `${mark.data_sale_online.is_value ? '$' : ''}
                    ${mark.data_sale_online.value}
                    ${!mark.data_sale_online.is_value ? '%' : ''}`
                  }}
                </span>
              </td>
              <td
                v-for="(afiliado, j) in typeAfiliados"
                :key="j"
                class="text-center"
              >
                <fragment
                  v-for="(dataAfil, indAd) in mark.data_sale_afiliate"
                  :key="indAd"
                >
                  <fragment v-if="dataAfil.type_id === afiliado.id">
                    <span v-if="dataAfil.value">
                      {{
                        `${dataAfil.is_value ? '$' : ''}
                        ${dataAfil.value}
                        ${!dataAfil.is_value ? '%' : ''}`
                      }}
                    </span>
                  </fragment>
                </fragment>
              </td>
              <td
                v-if="permisos.includes('markup:edit') || permisos.includes('markup:destroy') || permisos.includes('*')"
              >
                <v-tooltip
                  v-if="permisos.includes('markup:edit') || permisos.includes('*')"
                  top
                  color="primary"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      icon
                      v-bind="attrs"
                      v-on="on"
                      @click="editItem(mark)"
                    >
                      <v-icon>{{ icons.mdiSquareEditOutline }}</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t('btn.edit') }}</span>
                </v-tooltip>
                <v-tooltip
                  v-if="permisos.includes('markup:destroy') || permisos.includes('*')"
                  top
                  color="primary"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      icon
                      v-bind="attrs"
                      v-on="on"
                      @click="deleteItem(mark)"
                    >
                      <v-icon>{{ icons.mdiDeleteOutline }}</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t('btn.delete') }}</span>
                </v-tooltip>
              </td>
            </tr>
          </fragment>-->
        </tbody>
      </template>
    </v-simple-table>

    <!-- MOSTRAR DELETE -->
    <v-dialog
      v-model="isDialogVisibleDelete"
      scrollable
      max-width="380px"
    >
      <v-card max-height="400px">
        <v-card-title>{{ `${$t('btn.delete')}` }}</v-card-title>
        <v-divider></v-divider>
        <v-card-text>{{ $t('msg.deleteMarkups') }}</v-card-text>
        <v-divider></v-divider>

        <v-card-actions>
          <v-btn
            color="primary"
            outlined
            @click="isDialogVisibleDelete = !isDialogVisibleDelete"
          >
            {{ $t('btn.close') }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            outlined
            :loading="loading"
            @click="confirmDelete()"
          >
            {{ $t('btn.send') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'

import {
  mdiMagnify,
  mdiDeleteOutline,
  mdiSquareEditOutline,
  mdiPlus,
  mdiArrowLeft,
  mdiUpdate,
  mdiEyePlus,
  mdiEyeOutline,
  mdiEyeOffOutline,
  mdiFormatListText,
  mdiEraserVariant,
  mdiArrowUp,
  mdiArrowDown,
  mdiMenuDown,
  mdiMenuRight,
  mdiEyeCheck,
} from '@mdi/js'

export default {
  props: {
    isComition: {
      type: Boolean,
    },
    // eslint-disable-next-line vue/require-default-prop
    proveedoresHotel: {
      type: Array,
    },
    // eslint-disable-next-line vue/require-default-prop
    contratesHotel: {
      type: Array,
    },
    // eslint-disable-next-line vue/require-default-prop
    hotels: {
      type: Array,
    },
    // eslint-disable-next-line vue/require-default-prop
    rooms: {
      type: Array,
    },
    // eslint-disable-next-line vue/require-default-prop
    typeAfiliados: {
      type: Array,
    },
    // eslint-disable-next-line vue/require-default-prop
    user: {
      type: Object,
    },
  },

  data() {
    return {
      isLoading: true,
      rutaPublic: process.env.VUE_APP_API_URL_PLUBLIC,
      search: '',
      items: [],
      pagination: {
        current: 1,
        total: 0,
      },
      totalItems: 0,
      totalF: 0,

      showRowProduct: false,
      showRowProveedor: false,
      showRowContrate: false,
      showRowHotel: false,
      showRowRoom: false,
      showRowTemporada: false,
      showRowDate: false,

      // itemsPerPage: 10,
      icons: {
        mdiMagnify,
        mdiDeleteOutline,
        mdiSquareEditOutline,
        mdiPlus,
        mdiArrowLeft,
        mdiUpdate,
        mdiEyePlus,
        mdiEyeOutline,
        mdiEyeOffOutline,
        mdiFormatListText,
        mdiEraserVariant,
        mdiArrowUp,
        mdiArrowDown,
        mdiMenuDown,
        mdiMenuRight,
        mdiEyeCheck,
      },
      isDialogVisible: false,
      model: {},
      itemsOrigen: [],
      searchOrigen: null,
      itemsDestino: [],
      searchDestino: null,
      loading: false,

      showRow: false,
      indexRow: -1,

      proveedores: [],
      aerolineas: [],

      item: {},
      itemId: null,
      isDialogVisibleDelete: false,
      abilities: [],
      abilityList: [],
      checkPermiso: 1,
      isLoad: true,
      permisos: sessionStorage.getItem('permisos_auth'),
    }
  },
  computed: {
    ...mapState({
      markupsList: state => state.app.markupsList,
    }),
    itemsProduct() {
      if (this.markupsList.length > 0) {
        const newArr = []
        this.markupsList.forEach(element => {
          if (
            // eslint-disable-next-line eqeqeq
            element.is_comition == this.isComition
            && (element.products.includes('hotels') || element.products.includes('all'))
          ) {
            let identificador = ''
            if (element.products.includes('all')) {
              identificador = 'Hoteles'

              // identificador = 'Todos'
            } else {
              identificador = 'Hoteles'

              /* if (element.products.includes('hotels')) {
                identificador += ', Hoteles'
              }
              if (element.products.includes('flights')) {
                identificador += ', Vuelos'
              } */
            }

            if (
              (element.proveedor_id.length === 0
                || (element.proveedor_id.length === 1 && element.proveedor_id.includes(-1)))
              && element.contrate_id.length === 0
              && element.hotel_id.length === 0
              && element.room_id.length === 0
              && element.temporada_id.length === 0
            ) {
              newArr.push({
                identificador,
                ...element,
              })
            }
          }
        })

        return newArr
      }

      return []
    },
    itemsProveedor() {
      if (this.markupsList.length > 0) {
        const newArr = []
        this.markupsList.forEach(element => {
          if (
            // eslint-disable-next-line eqeqeq
            element.is_comition == this.isComition
            && (element.products.includes('hotels') || element.products.includes('all'))
          ) {
            if (
              element.proveedor_id.length > 0
              && !element.proveedor_id.includes(-1)
              && ((element.contrate_id.length === 1 && element.contrate_id.includes(-1))
                || element.contrate_id.length === 0)
              && element.hotel_id.length === 0
              && element.room_id.length === 0
              && element.temporada_id.length === 0
            ) {
              element.proveedor_id.forEach(provee => {
                let identificador = ''
                const ppp = this.proveedoresHotel.filter(e => e.id === provee)
                if (ppp.length > 0) {
                  identificador = ppp[0].name_comercial
                } else {
                  identificador = 'Todos'
                }
                newArr.push({
                  identificador,
                  ...element,
                })
              })
            }
          }
        })

        return newArr
      }

      return []
    },
    itemsContrate() {
      if (this.markupsList.length > 0) {
        const newArr = []
        this.markupsList.forEach(element => {
          if (
            // eslint-disable-next-line eqeqeq
            element.is_comition == this.isComition
            && (element.products.includes('hotels') || element.products.includes('all'))
          ) {
            if (
              element.proveedor_id.length === 1
              && element.contrate_id.length > 0
              && !element.contrate_id.includes(-1)
              && element.room_id.length === 0
              && element.temporada_id.length === 0
            ) {
              element.contrate_id.forEach(ite => {
                let identificador = ''
                const ppp = this.contratesHotel.filter(e => e.id === ite)
                if (ppp.length > 0) {
                  identificador = ppp[0].identificador
                } else {
                  identificador = 'Todos'
                }
                newArr.push({
                  identificador,
                  ...element,
                })
              })
            }
          }
        })

        return newArr
      }

      return []
    },
    itemsHotel() {
      if (this.markupsList.length > 0) {
        const newArr = []
        this.markupsList.forEach(element => {
          if (
            // eslint-disable-next-line eqeqeq
            element.is_comition == this.isComition
            && (element.products.includes('hotels') || element.products.includes('all'))
          ) {
            if (
              element.proveedor_id.length === 1
              && !element.proveedor_id.includes(-1)
              && element.contrate_id.length === 1
              && !element.contrate_id.includes(-1)
              && element.hotel_id.length > 0
              && !element.hotel_id.includes(-1)
              && (element.room_id.length === 0 || (element.room_id.length === 1 && element.room_id.includes(-1)))
              && (element.temporada_id.length === 0
                || (element.temporada_id.length === 1 && element.temporada_id.includes('all')))
            ) {
              element.hotel_id.forEach(ite => {
                let identificador = ''
                const cont = this.contratesHotel.filter(e => e.id === element.contrate_id[0])
                if (cont.length > 0) {
                  identificador = cont[0].identificador
                }

                const ppp = this.hotels.filter(e => e.id === ite)
                if (ppp.length > 0) {
                  identificador += `, ${ppp[0].name}`
                } else {
                  identificador += ', Todos'
                }
                newArr.push({
                  identificador,
                  ...element,
                })
              })
            }
          }
        })

        return newArr
      }

      return []
    },
    itemsRooms() {
      if (this.markupsList.length > 0) {
        const newArr = []
        this.markupsList.forEach(element => {
          if (
            // eslint-disable-next-line eqeqeq
            element.is_comition == this.isComition
            && (element.products.includes('hotels') || element.products.includes('all'))
          ) {
            if (
              element.proveedor_id.length === 1
              && !element.proveedor_id.includes(-1)
              && element.contrate_id.length === 1
              && !element.contrate_id.includes(-1)
              && element.hotel_id.length === 1
              && !element.hotel_id.includes(-1)
              && element.room_id.length > 0
              && !element.room_id.includes(-1)
              && (element.temporada_id.length === 0
                || (element.temporada_id.length === 1 && element.temporada_id.includes('all')))
            ) {
              element.hotel_id.forEach(ite => {
                let identificador = ''
                const cont = this.contratesHotel.filter(e => e.id === element.contrate_id[0])
                if (cont.length > 0) {
                  identificador = cont[0].identificador
                }

                const ppp = this.hotels.filter(e => e.id === ite)
                let nameHotel = ''
                if (ppp.length > 0) {
                  nameHotel = `${ppp[0].name}`
                }

                element.room_id.forEach(ror => {
                  let nameRoom = ''
                  const hab = this.rooms.filter(e => e.id === ror)
                  if (hab.length > 0) {
                    nameRoom = hab[0].name
                  }

                  newArr.push({
                    identificador: `${identificador}, ${nameHotel}, ${nameRoom}`,
                    ...element,
                  })
                })
              })
            }
          }
        })

        return newArr
      }

      return []
    },
    itemsTemporada() {
      if (this.markupsList.length > 0) {
        const newArr = []
        this.markupsList.forEach(element => {
          if (
            // eslint-disable-next-line eqeqeq
            element.is_comition == this.isComition
            && (element.products.includes('hotels') || element.products.includes('all'))
          ) {
            if (
              element.proveedor_id.length === 1
              && !element.proveedor_id.includes(-1)
              && element.contrate_id.length === 1
              && !element.contrate_id.includes(-1)
              && element.hotel_id.length === 1
              && !element.hotel_id.includes(-1)
              && element.temporada_id.length > 0
              && !element.temporada_id.includes('all')
            ) {
              let identificador = ''
              const cont = this.contratesHotel.filter(e => e.id === element.contrate_id[0])
              const { seasons } = cont[0]

              let nameHotel = 'Todos'
              const hott = this.hotels.filter(e => e.id === element.hotel_id[0])
              if (hott.length > 0) {
                nameHotel = hott[0].name
              }

              // eslint-disable-next-line no-unused-vars
              element.temporada_id.forEach(ite => {
                if (cont.length > 0) {
                  identificador = cont[0].identificador
                  // eslint-disable-next-line no-empty
                  let nameSeason = 'Todas'
                  if (ite !== 'all') {
                    const sss = seasons[0].seasons.seasons
                    sss.forEach(seasss => {
                      if (seasss.temporada_id === ite) {
                        nameSeason = seasss.name
                      }
                    })
                  }
                  element.room_id.forEach(roo => {
                    const ppp = this.rooms.filter(e => e.id === roo)
                    let nameRoom = 'Todas'
                    if (ppp.length > 0) {
                      nameRoom = ppp[0].name
                    }

                    newArr.push({
                      identificador: `${identificador}, ${nameHotel}, ${nameRoom}, ${nameSeason}`,
                      nameSeason,
                      ...element,
                    })
                  })
                }
              })
            }
          }
        })

        return newArr
      }

      return []
    },
    itemsDates() {
      if (this.markupsList.length > 0) {
        const newArr = []
        this.markupsList.forEach(element => {
          if (
            // eslint-disable-next-line eqeqeq
            element.is_comition == this.isComition
            && (element.products.includes('cars') || element.products.includes('all'))
          ) {
            if (
              element.proveedor_id.length === 1
              && !element.proveedor_id.includes(-1)
              && element.contrate_id.length === 1
              && !element.contrate_id.includes(-1)
              && (element.category_id.length > 0 || element.marca_modelo_id.length > 0)
              && element.temporada_id.length > 0
              && !element.temporada_id.includes('all')
              && element.from
              && element.to
            ) {
              // eslint-disable-next-line no-unused-vars
              let identificador = ''
              const cont = this.contratesCar.filter(e => e.id === element.contrate_id[0])
              if (cont.length > 0) {
                identificador = cont[0].identificador
                // eslint-disable-next-line no-empty
                if (cont[0].tipo_contrato === 'categoria') {
                  if (element.category_id.length > 0) {
                    element.category_id.forEach(cate => {
                      const ppp = this.categoriesCar.filter(e => e.id === cate)
                      let nameCat = 'Todas'
                      if (ppp.length > 0) {
                        nameCat = ppp[0].name
                      }

                      newArr.push({
                        identificador: `${identificador}, ${nameCat}, ${this.$moment(element.from).format(
                          'D/MMM/YY',
                        )} - ${this.$moment(element.to).format('D/MMM/YY')}`,
                        ...element,
                      })
                    })
                  }
                } else if (element.marca_modelo_id.length > 0) {
                  element.marca_modelo_id.forEach(marc => {
                    const ppp = this.marcasCar.filter(e => e.id === marc)
                    let nameMarc = 'Todas'
                    if (ppp.length > 0) {
                      nameMarc = ppp[0].name
                    }

                    newArr.push({
                      identificador: `${identificador}, ${nameMarc}, ${this.$moment(element.from).format(
                        'D/MMM/YY',
                      )} - ${this.$moment(element.to).format('D/MMM/YY')}`,
                      ...element,
                    })
                  })
                }
              }
            }
          }
        })

        return newArr
      }

      return []
    },
  },
  mounted() {
    setTimeout(() => {
      this.isLoading = false
    }, 100)
  },
  methods: {
    ...mapMutations(['updateMarkupsList', 'deleteMarkupsList']),
    showItemProduct() {
      this.showRowProduct = !this.showRowProduct
    },
    showItemProveedor() {
      this.showRowProveedor = !this.showRowProveedor
    },
    showItemContrate() {
      this.showRowContrate = !this.showRowContrate
    },
    showItemHotel() {
      this.showRowHotel = !this.showRowHotel
    },
    showItemRoom() {
      this.showRowRoom = !this.showRowRoom
    },
    showItemTemporada() {
      this.showRowTemporada = !this.showRowTemporada
    },
    showItemDate() {
      this.showRowDate = !this.showRowDate
    },
    loadAll() {
      const json = {
        page: this.pagination.current,
        per_page: this.$store.state.app.itemsPerPage,
        nom_tipo_product_slug: 'vuelos',
        perfil_slug: sessionStorage.getItem('perfil'),
      }
      this.axios
        .post('markups/list', json, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          // eslint-disable-next-line no-empty
          if (res.data.success === false) {
          } else {
            this.totalItems = res.data.meta.total
          }
        })
    },
    editItem(mark) {
      this.$emit('asign:markups', mark.id)
      window.scrollTo(0, 0)
    },
    deleteItem(iten) {
      this.isDialogVisibleDelete = true
      this.item = iten
      this.itemId = iten.id
    },
    confirmDelete() {
      this.loading = true
      this.axios
        .delete(`markups/${this.itemId}`, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          if (res.data.success === false) {
            if (res.data.data.status === 401) {
              // this.$toast.error(this.$t('msg.noAutorice'))
            }
          } else {
            this.$toast.success(this.$t('msg.infoSuccess'))
            this.$emit('load')
          }
        })
        // eslint-disable-next-line no-return-assign
        .finally(() => this.reseat())
    },
    reseat() {
      this.loading = false
      this.isDialogVisibleDelete = false
    },

    showItem(index) {
      if (this.indexRow === index) {
        this.showRow = !this.showRow
      } else if (!this.showRow) {
        this.showRow = !this.showRow
      }
      this.indexRow = index
    },
  },
}
</script>

<style lang="scss" scoped>
.table-kitchen-sink ::v-deep {
  .v-data-table-header {
    white-space: nowrap;
  }
}
</style>
